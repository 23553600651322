import React from 'react'
import { Link } from 'react-router-dom'
import { imgDevice } from './util'

export default function Device({ device }) {
  return (
    <section className="dispositivos">
      <div>
        <img width={25} height={25} src={imgDevice[device.deviceOS]} />
        <span>{device.descriptiveName}</span>
      </div>
      <Link to={{ pathname: 'detalhar-dispositivo', device }}>DETALHAR</Link>
    </section>
  )
}
