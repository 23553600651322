import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAppState } from '../../AppContext'
import { changeInfoTela } from '../../layout/actions'
import { isDeviceAuthorizer, detailedName } from './util'

const componentDidMount = (dispatch, state, name) => () => {
  dispatch(
    changeInfoTela({
      title: name,
      breadcrumbs: [
        { link: '/seguranca', label: 'Segurança' },
        {
          link: '/seguranca/gerenciar-dispositivos',
          label: 'Gerenciar dispositivos',
        },
        { label: name },
      ],
    })
  )
}

function DeviceDetailed() {
  const [state, dispatch] = useAppState()
  const location = useLocation()
  const device = location.device || {}

  useEffect(componentDidMount(dispatch, state, device?.deviceOS), [])

  const isAuthorizer = isDeviceAuthorizer(device)

  return (
    <>
      <div className="device-title-status">
        <span>{device.descriptiveName}</span>
        <span>
          {!isAuthorizer &&
            (device.authorized ? (
              <>
                <img src="/images/autorizado-green.svg" /> AUTORIZADO
              </>
            ) : (
              <>
                <img src="/images/pending-blue.svg" /> NÃO AUTORIZADO
              </>
            ))}
        </span>
      </div>

      <div className="device-info-container">
        <div className="device-info">
          {device['latitude'] && device['longitude'] && (
            <>
              <span>{detailedName['geolocatedAddress']}</span>
              <span>
                {device['geolocatedAddress'] +
                  ' (Latitude: ' +
                  device['latitude'] +
                  ' - Longitude: ' +
                  device['longitude'] +
                  ')'}
              </span>
            </>
          )}
          {!isAuthorizer && (
            <>
              <span>{detailedName['authorizationDate']}</span>
              <span>
                {device.authorized
                  ? new Date(device['authorizationDate']).toLocaleString(
                      'pt-BR'
                    )
                  : 'NÃO AUTORIZADO'}
              </span>
            </>
          )}
          <span>{detailedName['lastAccessDate']}</span>
          <span>
            {new Date(device['lastAccessDate']).toLocaleString('pt-BR')}
          </span>
          {!isAuthorizer && (
            <>
              <span>{detailedName['lastClientName']}</span>
              <span>{device['lastClientName']}</span>
              <span>{detailedName['deviceBrowser']}</span>
              <span>{device['deviceBrowser']}</span>
            </>
          )}
          <span>
            <div style={{ float: 'left', marginRight: '5px' }}>
              {detailedName['ip']}
            </div>
            <div className="tooltip">
              <img src="/images/circle-info.svg" />
              <span className="tooltiptext">
                É um endereço exclusivo que identifica um dispositivo na
                internet ou em uma rede local.
              </span>
            </div>
          </span>
          <span>{device['ip']}</span>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Link
            to="/seguranca/gerenciar-dispositivos"
            className="action-tertiary"
          >
            Voltar
          </Link>
        </div>
      </div>
    </>
  )
}

export default DeviceDetailed
