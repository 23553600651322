import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

function ModalLevelUp({ open, setOpen }) {
  const close = () => {
    setOpen(false)
  }
  return (
    <Modal
      isOpen={open}
      onRequestClose={close}
      contentLabel="Nome"
      className="modal"
    >
      <div>
        <span className="page-title">
          Você precisa aumentar o nível da sua conta para gerenciar dispositivos
        </span>
        <p>
          Clique em "Aumentar nível" e siga as orientações para adquirir o nível{' '}
          <b>Prata</b> ou <b>Ouro</b>.
        </p>
        <div style={{ textAlign: 'center' }}>
          <button
            className="action-primary"
            onClick={() =>
              (window.location = `${process.env.REACT_APP_CATALOGO_URL}`)
            }
          >
            Aumentar nível
          </button>
          <button className="action-secondary" onClick={close}>
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalLevelUp
