import './style.css'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppState } from '../AppContext'
import { changeInfoTela } from '../layout/actions'
import Card from '../commons/Card'

const componentDidMount = (dispatch, logged_user, history) => () => {
  //Recurso necessário para voltar à mesma tela após uma reautenticação causada pela perda da sessão ou um acesso direto ao contexto via barra de endereços. Exemplo: https://contas.local.acesso.gov.br/alteracao_cadastro
  //Ver fetch-api.js 'REQUEST_SESSION_MUSTEXISTS'
  if (
    sessionStorage.getItem('contextRedirect') &&
    sessionStorage.getItem('contextRedirect') !== 'undefined' &&
    sessionStorage.getItem('contextRedirect') !== '/'
  ) {
    let contextRedirect = sessionStorage.getItem('contextRedirect')
    sessionStorage.removeItem('contextRedirect')
    history.push(contextRedirect)
    return
  }

  let portalRedirect = `${process.env.REACT_APP_PORTAL_LOGADO_ENABLED}`
  let servicosRedirect = `${process.env.REACT_APP_PORTAL_LOGADO_URL}`

  if (portalRedirect === 'true' || portalRedirect === 'TRUE') {
    window.location.href = servicosRedirect
    return
  }

  dispatch(changeInfoTela({ title: 'Início' }))
}

function Home() {
  const [{ logged_user }, dispatch] = useAppState()
  const history = useHistory()
  const relLevel = logged_user.reliabilityLevel
  let portalRedirect = `${process.env.REACT_APP_PORTAL_LOGADO_ENABLED}`

  useEffect(componentDidMount(dispatch, logged_user, history), [])

  return (
    <>
      {logged_user.name &&
        (portalRedirect === 'false' || portalRedirect === 'FALSE') && (
          <>
            <div>
              <>
                <img id="foto" src={logged_user.photoBase64} />
                <span id="hello-text">
                  Olá, <strong>{logged_user.name}</strong>
                </span>
              </>

              <div id="show-level">
                <p style={{ margin: 0 }}>
                  SUA CONTA GOV.BR É NÍVEL{' '}
                  <strong>
                    {relLevel === 1 && 'BRONZE'}
                    {relLevel === 2 && 'PRATA'}
                    {relLevel === 3 && 'OURO'}
                  </strong>
                </p>
                {relLevel === 1 && (
                  <img
                    id="imglevel"
                    alt="Nível"
                    src={`${process.env.REACT_APP_URL_ASSETS}/images/bronze_horizontal.png`}
                  />
                )}
                {relLevel === 2 && (
                  <img
                    id="imglevel"
                    alt="Nível"
                    src={`${process.env.REACT_APP_URL_ASSETS}/images/silver_horizontal.png`}
                  />
                )}
                {relLevel === 3 && (
                  <img
                    id="imglevel"
                    alt="Nível"
                    src={`${process.env.REACT_APP_URL_ASSETS}/images/gold_horizontal.png`}
                  />
                )}

                <div id="btn-level">
                  <button
                    className={
                      relLevel < 3 ? 'aumentar-lvl-button' : 'ver-lvl-button'
                    }
                    id="lvl-button"
                    onClick={() =>
                      (window.location = `${process.env.REACT_APP_CATALOGO_URL}`)
                    }
                  >
                    {relLevel < 3 ? 'AUMENTAR' : 'VER'} NÍVEL
                  </button>
                </div>
              </div>
            </div>

            <p>
              Gerencie sua conta gov.br, acompanhe os serviços solicitados e
              assine documentos digitalmente
            </p>

            <h3>Minha conta</h3>
            <hr />

            <div className="home-cards">
              <Card
                linkTo="/alteracao_cadastro"
                imgSrc="/images/dados-pessoais-medium.png"
                title="Dados pessoais"
                description="Ver e alterar dados pessoais e de contato"
              />

              <Card
                linkTo="/seguranca"
                warning={
                  relLevel !== 3 && '/images/exclamation-circle-solid.png'
                }
                imgSrc="/images/seguranca-da-conta-medium.png"
                title="Segurança da conta"
                description="Aumentar o nível, alterar senha, e habilitar verificação em 2 etapas"
              />

              <Card
                linkTo="/privacidade"
                imgSrc="/images/privacidade-da-conta-medium.png"
                title="Privacidade"
                description="Ver histórico de login e gerenciar autorizações de uso dos dados"
              />

              {logged_user.cnpj && (
                <Card
                  linkTo="/empresas"
                  imgSrc="/images/key-solid-medium.png"
                  title="Vincular empresas via e-CNPJ"
                  description="Gerencie os colaboradores que podem interagir com o governo representando sua empresa."
                />
              )}
            </div>
          </>
        )}
    </>
  )
}

export default Home
