import { API_PATH } from '../../commons/constants'
import { changeLoggedUserData } from '../../commons/logged-user/actions'
import { changeMessage, changeMessageDelay } from '../layout/actions'
import fetchApi from '../../commons/fetch-api'
import { getPhotoAsBase64 } from '../../commons/commons'

export const CHANGE_FORM_VALUES_ACTION = 'CADASTRO/CHANGE_FORM_VALUES'
export const ADD_VALID_ERROR = 'CADASTRO/ADD_VALID_ERROR'
export const REMOVE_VALID_ERROR = 'CADASTRO/REMOVE_VALID_ERROR'
export const CLEAN_VALID_ERRORS = 'CADASTRO/CLEAN_VALID_ERRORS'
export const MODAL_EMAIL_VISIBILITY = 'CADASTRO/MODAL_EMAIL_VISIBILITY'
export const MODAL_PHONE_VISIBILITY = 'CADASTRO/MODAL_PHONE_VISIBILITY'

export const changeFormValues = data => ({
  type: CHANGE_FORM_VALUES_ACTION,
  data,
})

export const addValidationError = error => ({
  type: ADD_VALID_ERROR,
  error,
})

export const removeValidationError = field => ({
  type: REMOVE_VALID_ERROR,
  field,
})

export const cleanValidationErrors = () => ({
  type: CLEAN_VALID_ERRORS,
})

export const setModalEmailVisibility = data => ({
  type: MODAL_EMAIL_VISIBILITY,
  data,
})

export const setModalTelefoneVisibility = data => ({
  type: MODAL_PHONE_VISIBILITY,
  data,
})

export const saveFotoPerfil = fileInput => async (dispatch, state) => {
  const formData = new FormData()
  const file = fileInput.files[0]
  formData.append('file', file)
  const retornoObj = await fetchApi(
    API_PATH.conta.photo.replace(':id', state.logged_user.id),
    { method: 'POST', body: formData },
    true
  )
  if (retornoObj) {
    dispatch(changeFormValues({ fileName: file.name }))
    dispatch(
      changeLoggedUserData({
        photoBase64: await getPhotoAsBase64(
          API_PATH.conta.photo.replace(':id', state.logged_user.id)
        ),
      })
    )
    dispatch(
      changeMessageDelay({ type: 'SUCCESS', text: 'Foto alterada com sucesso' })
    )
  }
}

export const setInitialFormValues = loggedUser => {
  return changeFormValues({
    cpf: loggedUser.id,
    name: loggedUser.name,
    email: loggedUser.email,
    phoneNumber: loggedUser.phoneNumber,
    emailVerified: loggedUser.emailVerified,
    phoneNumberVerified: loggedUser.phoneNumberVerified,
  })
}

export const validateForm = (field, callback) => (dispatch, state) => {
  const fieldVazio = () => {
    return (
      state.form_alter_cadastro[field] === '' ||
      state.form_alter_cadastro[field].trim() === ''
    )
  }

  dispatch(removeValidationError(field))

  if (field === 'name') {
    if (fieldVazio()) {
      dispatch(addValidationError({ field, msg: 'Informe seu nome.' }))
    }
  }

  if (field === 'email') {
    if (fieldVazio()) {
      dispatch(addValidationError({ field, msg: 'Informe seu email.' }))
    } else if (
      !/^(?:([A-Za-z]|[0-9]|[!#$%&'*+\-/=?^_`{|}~])+(?:\.([A-Za-z]|[0-9]|[!#$%&'*+\-/=?^_`{|}~])+)*@([A-Za-z]|[0-9])(([A-Za-z]|[0-9]|-)*([A-Za-z]|[0-9]))?(\.([A-Za-z]|[0-9])(([A-Za-z]|[0-9]|-)*([A-Za-z]|[0-9]))?)+)$/.test(
        state.form_alter_cadastro['email'].toLowerCase()
      )
    ) {
      dispatch(addValidationError({ field, msg: 'E-mail inválido.' }))
      callback && callback(false)
    } else {
      callback && callback(true)
    }
  }

  if (field === 'phoneNumber') {
    if (fieldVazio()) {
      dispatch(addValidationError({ field, msg: 'Informe seu telefone.' }))
    } else if (
      window.removePhoneMask(state.form_alter_cadastro[field]).length !== 11
    ) {
      dispatch(addValidationError({ field, msg: 'Telefone inválido' }))
      callback && callback(false)
    } else {
      callback && callback(true)
    }
  }
}

export const generateCode = (tipo, callback) => async (dispatch, state) => {
  let pathRequest =
    tipo === 'email'
      ? API_PATH.conta.tokenAlteracaoEmail
      : API_PATH.conta.tokenAlteracaoPhone

  const retornoObj = await fetchApi(
    pathRequest.replace(':id', state.logged_user.id),
    {
      method: 'POST',
      body:
        tipo === 'email'
          ? JSON.stringify({ email: state.form_alter_cadastro.email })
          : JSON.stringify({
              phoneNumber: state.form_alter_cadastro.phoneNumber,
            }),
    }
  )

  if (retornoObj) {
    callback()
    tipo === 'email'
      ? dispatch(
          changeFormValues({
            email: state.form_alter_cadastro.email,
            token: retornoObj.token,
          })
        )
      : dispatch(
          changeFormValues({
            phoneNumber: state.form_alter_cadastro.phoneNumber,
            token: retornoObj.token,
          })
        )
  }
}

export const validateCode = tipo => async (dispatch, state) => {
  let formData = {}
  let pathRequest = ''

  if (tipo === 'email') {
    formData = {
      email: state.form_alter_cadastro.email,
      token: state.form_alter_cadastro.token,
      otp: state.form_alter_cadastro.otp,
    }
    pathRequest = API_PATH.conta.alteracoesEmail
  } else if (tipo === 'phone') {
    formData = {
      phoneNumber: window.formatToOnlyDigits(
        state.form_alter_cadastro.phoneNumber
      ),
      token: state.form_alter_cadastro.token,
      otp: state.form_alter_cadastro.otp,
    }
    pathRequest = API_PATH.conta.alteracoesPhone
  }

  dispatch(changeMessage({}))

  const retornoObj = await fetchApi(
    pathRequest.replace(':id', state.logged_user.id),
    {
      method: 'POST',
      body: JSON.stringify(formData),
    }
  )

  if (retornoObj) {
    if (tipo === 'email') {
      dispatch(
        changeMessageDelay({
          type: 'SUCCESS',
          text: 'E-mail alterado com sucesso.',
        })
      )
      dispatch(
        changeLoggedUserData({
          ...state.logged_user,
          email: state.form_alter_cadastro.email,
        })
      )
      dispatch(changeFormValues({ emailVerified: true, token: '', otp: '' }))
      dispatch(setModalEmailVisibility(false))
    } else if (tipo === 'phone') {
      dispatch(
        changeMessageDelay({
          type: 'SUCCESS',
          text: 'Telefone alterado com sucesso.',
        })
      )
      dispatch(
        changeLoggedUserData({
          ...state.logged_user,
          phoneNnumber: state.form_alter_cadastro.phoneNumber,
        })
      )
      dispatch(
        changeFormValues({ phoneNumberVerified: true, token: '', otp: '' })
      )
      dispatch(setModalTelefoneVisibility(false))
    }
  }
}
