export const isDeviceAuthorizer = device => {
  return device.id === 'authorization-device'
}

export const detailedName = {
  descriptiveName: 'Dispositivo',
  deviceBrowser: 'Navegador',
  geolocatedAddress: 'Localização aproximada',
  authorizationDate: 'Data da autorização',
  lastAccessDate: 'Último acesso',
  lastClientName: 'Último serviço público acessado',
  ip: 'IP',
}

export const imgDevice = {
  Linux: '/images/desktop.svg',
  Windows: '/images/desktop.svg',
  'Mac OS': '/images/desktop.svg',
  'Chrome OS': '/images/desktop.svg',
  'iOS-iPhone': '/images/cellphone.svg',
  'iOS-iPad': '/images/tablet.svg',
  Android: '/images/cellphone.svg',
  Desconhecido: '/images/desktop.svg',
}
