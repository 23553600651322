import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AlteracaoDadosCadastrais from '../dados-cadastrais/AlteracaoDadosCadastrais'
import Message from './Message.js'
import AlteracaoSenha from '../senha/AlteracaoSenha.js'
import Seguranca from '../seguranca/Seguranca'
import Privacidade from '../privacidade/Privacidade.js'
import Breadcrumb from './Breadcrumb.js'
import Home from '../home/Home'
import Empresas from '../empresas/Empresas'
import Endereco from '../endereco/Endereco'
import TwoFactorAuthentication from '../seguranca/two-factor-authentication/TwoFactorAuthentication'
import DeviceManager from '../seguranca/device-manager/DeviceManager'
import DeviceDetailed from '../seguranca/device-manager/DeviceDetailed'

function Content() {
  return (
    <main>
      <Breadcrumb />
      <Message />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/post-login">
          <Home />
        </Route>
        <Route path="/alteracao_cadastro">
          <AlteracaoDadosCadastrais />
        </Route>
        <Route path="/endereco">
          <Endereco />
        </Route>
        <Route exact path="/seguranca">
          <Seguranca />
        </Route>
        <Route path="/seguranca/alteracao_senha">
          <AlteracaoSenha />
        </Route>
        <Route path="/seguranca/habilitacao-segundo-fator">
          <TwoFactorAuthentication />
        </Route>
        <Route path="/seguranca/gerenciar-dispositivos">
          <DeviceManager />
        </Route>
        <Route path="/seguranca/detalhar-dispositivo">
          <DeviceDetailed />
        </Route>
        <Route path="/privacidade">
          <Privacidade />
        </Route>
        <Route path="/empresas">
          <Empresas />
        </Route>
      </Switch>
      {/*<div id="marca-serpro">
        <img
          src="https://cdn.dsgovserprodesign.estaleiro.serpro.gov.br/design-system/1.2.0/images/produto-serpro.png"
          alt="Produto Serpro"
        />
      </div>*/}
    </main>
  )
}

export default Content
