import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalLevelUp from './ModalLevelUp'

export default function Card(props) {
  const {
    linkTo,
    warning,
    imgSrc,
    title,
    description,
    useLink = true,
    levelUp = false,
  } = props

  const [openModalLevelUp, setOpenModalLevelUp] = useState(false)

  return (
    <>
      {useLink ? (
        <Link
          to={() => (levelUp ? '#' : linkTo)}
          onClick={() => levelUp && setOpenModalLevelUp(true)}
        >
          <div className="card">
            <img className="card-warn" src={warning} />
            <img className="card-icon" src={imgSrc} />
            <p>{title}</p>
            <p>{description}</p>
          </div>
        </Link>
      ) : (
        <a href={linkTo}>
          <div className="card">
            <img className="card-warn" src={warning} />
            <img className="card-icon" src={imgSrc} />
            <p>{title}</p>
            <p>{description}</p>
          </div>
        </a>
      )}

      <ModalLevelUp open={openModalLevelUp} setOpen={setOpenModalLevelUp} />
    </>
  )
}
