import '../style.css'
import React, { useEffect } from 'react'
import { changeInfoTela, changeMessage } from '../../layout/actions'
import { useAppState } from '../../AppContext'
import { isMobileOS } from '../../../utils/responsivity'

const componentDidMount = (dispatch, logged_user) => () => {
  let labelDescription = logged_user.multifator ? 'Desabilitar verificação em duas etapas' : 'Habilitar verificação em duas etapas';
  dispatch(
    changeInfoTela({
      title: 'Segurança',
      breadcrumbs: [
        { link: '/seguranca', label: 'Segurança' },
        { label: labelDescription },
      ],
    })
  )
}

function TwoFactorAuthentication() {
  const [{ logged_user }, dispatch] = useAppState()
  const isMobile = isMobileOS();
  useEffect(componentDidMount(dispatch, logged_user), [])

  const clickOpenApp = () => {
    window.location.assign('meugovbr://login')
  }
  const clickBackToMainMenu = () => {
    window.location.assign('/seguranca')
  }

  return (
    <> 
      { logged_user.multifator ? 
      <>
        {
            isMobile
                ? <>
                    <div id="webviewAppGovbr">
                        <p className="mt-5 mb-3 font-weight-bold">Acesse a opção <b>Segurança da conta</b> em seu aplicativo gov.br para desabilitar a Verificação em duas etapas.</p>
                    </div>
                </>
                : <>
                    <p className="margin-bt-2">A verificação em duas etapas só pode ser desabilitada pelo <b>aplicativo gov.br</b>, conforme passos abaixo:</p>
                    <div className="br-list horizontal justify-content-between pt-4">
                        <div className="col-two-factor-step">
                            <div>
                                <img src="/images/enable-2fa-step-01.png" alt="recupera" height="140px" />
                                <p className="mb-1"><strong>Passo 1:</strong></p>
                                <p className="mb-0">Abrir aplicativo e clicar em <strong>Segurança da conta</strong></p>
                            </div>
                        </div>
                        <div className="col-two-factor-step">
                            <div>
                                <img src="/images/enable-2fa-step-02.png" alt="recupera" height="140px" />
                                <p className="mb-1"><strong>Passo 2:</strong></p>
                                <p className="mb-0">Clicar no card <strong>Verificação em duas etapas</strong></p>
                            </div>
                        </div>
                        <div className="col-two-factor-step">
                            <div>
                                <img src="/images/enable-2fa-step-03.png" alt="recupera" height="140px" />
                                <p className="mb-1"><strong>Passo 3:</strong></p>
                                <p className="mb-0">Clicar em <strong>Desabilitar verificação em duas etapas</strong></p>
                            </div>
                        </div>
                    </div>
                </>
        }
        
        {
            isMobile
                ? <>
                    <button
                      type="button"
                      className="action-primary"
                      onClick={clickOpenApp}
                    >
                      Abrir aplicativo gov.br
                    </button>
                    <button
                      className="action-tertiary"
                      type="button"
                      onClick={clickBackToMainMenu}
                    >
                      Voltar
                    </button>
                </>
                : <>
                    <button
                      className="action-tertiary"
                      type="button"
                      onClick={clickBackToMainMenu}
                    >
                      Voltar
                  </button>
                </>
        }
      </> 
      : 
      <>
        {
            isMobile
                ? <>
                    <div id="webviewAppGovbr">
                        <p className="mt-5 mb-3 font-weight-bold">Acesse a opção <b>Segurança da conta</b> em seu aplicativo gov.br para habilitar a Verificação em duas etapas.</p>
                    </div>
                </>
                : <>
                    <p className="margin-bt-2">A verificação em duas etapas só pode ser habilitada pelo <b>aplicativo gov.br</b>, conforme passos abaixo:</p>
                    <div className="br-list horizontal justify-content-between pt-4">
                        <div className="col-two-factor-step">
                            <div>
                                <img src="/images/enable-2fa-step-01.png" alt="recupera" height="140px" />
                                <p className="mb-1"><strong>Passo 1:</strong></p>
                                <p className="mb-0">Abrir aplicativo e clicar em <strong>Segurança da conta</strong></p>
                            </div>
                        </div>
                        <div className="col-two-factor-step">
                            <div>
                                <img src="/images/enable-2fa-step-02.png" alt="recupera" height="140px" />
                                <p className="mb-1"><strong>Passo 2:</strong></p>
                                <p className="mb-0">Clicar no card <strong>Verificação em duas etapas</strong></p>
                            </div>
                        </div>
                        <div className="col-two-factor-step">
                            <div>
                                <img src="/images/enable-2fa-step-03.png" alt="recupera" height="140px" />
                                <p className="mb-1"><strong>Passo 3:</strong></p>
                                <p className="mb-0">Habilitar Verificação em duas etapas</p>
                            </div>
                        </div>
                    </div>
                </>
        }
        
        {
            isMobile
                ? <>
                    <button
                      type="button"
                      className="action-primary"
                      onClick={clickOpenApp}
                    >
                      Abrir aplicativo gov.br
                    </button>
                    <button
                      className="action-tertiary"
                      type="button"
                      onClick={clickBackToMainMenu}
                    >
                      Voltar
                    </button>
                </>
                : <>
                    <button
                      className="action-tertiary"
                      type="button"
                      onClick={clickBackToMainMenu}
                    >
                      Voltar
                  </button>
                </>
        }
      </>
      }
    </>
  );
}

export default TwoFactorAuthentication
