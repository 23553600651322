import { INITIAL_STATE } from '../../commons/constants'

export const CHANGE_FORM_VALUES_ACTION = 'SEGURANCA/CHANGE_FORM_VALUES'

export const setInitialFormValues = () => {
  return changeFormValues({
    ...INITIAL_STATE.form_seguranca,
  })
}

export const changeFormValues = data => ({
  type: CHANGE_FORM_VALUES_ACTION,
  data,
})

export const setTwoFactorOperation = operation => {
  return changeFormValues({
    operation,
  })
}

