import {
  ADD_VALID_ERROR,
  CHANGE_FORM_VALUES_ACTION,
  CLEAN_VALID_ERRORS,
  MODAL_EMAIL_VISIBILITY,
  MODAL_PHONE_VISIBILITY,
  REMOVE_VALID_ERROR,
} from './actions'
import { REMOVE_ADDRESS } from '../endereco/actions'

export const formAlteracaoCadastroReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_FORM_VALUES_ACTION:
      return { ...state, ...action.data }

    case ADD_VALID_ERROR:
      return { ...state, errors: [...state.errors, action.error] }

    case REMOVE_VALID_ERROR:
      return {
        ...state,
        errors: state.errors.filter(e => e.field !== action.field),
      }

    case REMOVE_ADDRESS:
      return {
        ...state,
        addresses: state.addresses.filter(s => s.idType !== action.idType),
      }

    case CLEAN_VALID_ERRORS:
      return { ...state, errors: [] }

    default:
      return state
  }
}

export const modal = (state, action) => {
  switch (action.type) {
    case MODAL_EMAIL_VISIBILITY:
      return { ...state, email_open: action.data }
    case MODAL_PHONE_VISIBILITY:
      return { ...state, phone_open: action.data }
    default:
      return state
  }
}
