import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppState } from '../../AppContext'
import { changeInfoTela } from '../../layout/actions'
import './deviceManager.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { getDevices } from './actions'
import Collapsible from './Collapsible'
import { isDeviceAuthorizer } from './util'

function DeviceManager() {
  const [state, dispatch] = useAppState()
  const location = useLocation()

  useEffect(
    () =>
      dispatch(
        changeInfoTela({
          title: 'Gerenciar dispositivos',
          breadcrumbs: [
            { link: '/seguranca', label: 'Segurança' },
            { label: 'Gerenciar dispositivos' },
          ],
        })
      ),
    []
  )
  const [devices, setDevices] = useState([])

  const [onlyAuthDevices, setOnlyAuthDevices] = useState(true)
  useEffect(() => dispatch(getDevices(setDevices, setOnlyAuthDevices)), [
    location.pathname,
  ])

  const history = useHistory()

  const authorizedDevices = devices?.filter(
    d => d.authorized && !isDeviceAuthorizer(d)
  )

  const allDevices = devices?.filter(
    d => !isDeviceAuthorizer(d)
  )

  return (
    <>
      <span className="page-title">
        <div className="back-icon" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        Gerenciar dispositivos
      </span>

      <p style={{ color: '#636363' }}>
        Dispositivos autorizados são aqueles que você escolheu "Não solicitar a verificação em duas etapas novamente para este dispositivo".
      </p>
      <p style={{ color: '#636363', marginTop: 0 }}>
        O acesso por diferentes navegadores, ainda que utilizando o mesmo
        aparelho, será apresentado como um novo dispositivo.
      </p>

      <Collapsible
        title="Autorizados"
        devices={authorizedDevices.reverse()}
        icon="/images/autorizados.svg"
      />

      <Collapsible
        title="Histórico"
        devices={allDevices.reverse()}
        icon="/images/autorizados.svg"
      />

    </>
  )
}

export default DeviceManager
