import './style.css'
import React, { useEffect } from 'react'
import { useAppState } from '../AppContext'
import { changeInfoTela } from '../layout/actions'
import {
  setInitialFormValues,
  setModalEmailVisibility,
  setModalTelefoneVisibility,
} from './actions'
import { retrieveAddresses } from '../endereco/actions'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ModalEmail from './email-validation/ModalEmail'
import ModalTelefone from './telefone-validation/ModalTelefone'
import UploadPhoto from './UploadPhoto'
import FormField from './FormField'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const componentDidMount = (dispatch, state) => () => {
  dispatch(
    changeInfoTela({
      title: 'Dados pessoais',
      breadcrumbs: [{ label: 'Dados pessoais' }],
    })
  )

  if (!state.form_alter_cadastro.cpf && state.logged_user.id) {
    dispatch(setInitialFormValues(state.logged_user))
    dispatch(retrieveAddresses())
  }
}

function AlteracaoDadosCadastrais() {
  const [state, dispatch] = useAppState()
  const history = useHistory()
  const form_alter_cadastro = state.form_alter_cadastro
  const addresses = state.form_alter_cadastro.addresses
  const relLevel = state.logged_user.reliabilityLevel
  useEffect(componentDidMount(dispatch, state), [])

  const openEmailModal = () => {
    dispatch(setModalEmailVisibility(true))
  }

  const openTelefoneModal = () => {
    dispatch(setModalTelefoneVisibility(true))
  }

  let defaultAddress = addresses.filter(
    address => address.defaultAddress !== 'undefined' && address.defaultAddress
  )[0]
  defaultAddress = defaultAddress ? defaultAddress : addresses[0]
  const { address, city, state: estado } = defaultAddress
    ? defaultAddress
    : { address: '', city: '' }

  return (
    <>
      <span className="page-title">
        <div className="back-icon" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        Dados pessoais
      </span>

      <div className="container-cadastro">
        <div className="container-formulario">
          <span className="sub-page-title">Dados cadastrais</span>

          <FormField
            imgSrc="/images/card-icon-medium.png"
            label="CPF"
            inputValue={form_alter_cadastro.cpf}
            onlySpan={true}
          />

          <FormField
            imgSrc="/images/portrait-icon-medium.png"
            label="Nome"
            inputValue={form_alter_cadastro.name}
            onlySpan={true}
          />

          <FormField
            imgSrc="/images/email-icon-medium.png"
            label="E-mail"
            inputValue={form_alter_cadastro.email}
            faIcon={faEdit}
            upsertFunction={openEmailModal}
          />

          <FormField
            imgSrc="/images/phone-icon-medium.png"
            label="Celular para receber SMS"
            inputValue={window.formatPhone(form_alter_cadastro.phoneNumber)}
            faIcon={faEdit}
            upsertFunction={openTelefoneModal}
          />

          <FormField
            imgSrc="/images/address-icon-medium.png"
            label="Endereço"
            inputValue={
              addresses.length ? address + ', ' + city + '-' + estado : ''
            }
            faIcon={faEdit}
            upsertFunction={() => (window.location.href = '/endereco')}
          />
        </div>

        <div className="container-foto">
          <span className="sub-page-title">Foto</span>
          <UploadPhoto />
          <span className="sub-page-title">Nível da conta</span>
          <div className="card-info-level">
            <p style={{ margin: 0 }}>
              SUA CONTA GOV.BR É NÍVEL{' '}
              <strong>
                {relLevel === 1 && 'BRONZE'}
                {relLevel === 2 && 'PRATA'}
                {relLevel === 3 && 'OURO'}
              </strong>
            </p>
            {relLevel === 1 && (
              <img
                id="imglevel"
                alt="Nível"
                src={`${process.env.REACT_APP_URL_ASSETS}/images/bronze_horizontal.png`}
              />
            )}
            {relLevel === 2 && (
              <img
                id="imglevel"
                alt="Nível"
                src={`${process.env.REACT_APP_URL_ASSETS}/images/silver_horizontal.png`}
              />
            )}
            {relLevel === 3 && (
              <img
                id="imglevel"
                alt="Nível"
                src={`${process.env.REACT_APP_URL_ASSETS}/images/gold_horizontal.png`}
              />
            )}

            {relLevel < 3 && (
              <>
                <img
                  className="img-level-warning"
                  src="/images/exclamation-circle-solid.png"
                  alt=""
                />
                {relLevel === 1 && (
                  <span className="span-level-warning">
                    Aumente o nível da sua conta para acessar sua carteira de
                    documentos, assinar documentos digitais e outras
                    funcionalidades do aplicativo gov.br.
                  </span>
                )}
                {relLevel === 2 && (
                  <span className="span-level-warning">
                    Aumente o nível da sua conta para ter ainda mais segurança
                    no seu acesso.
                  </span>
                )}
              </>
            )}

            <div id="btn-level">
              <button
                className={
                  relLevel < 3
                    ? 'aumentar-lvl-button-primary'
                    : 'ver-lvl-button-primary'
                }
                id="lvl-button-primary"
                onClick={() =>
                  (window.location = `${process.env.REACT_APP_CATALOGO_URL}`)
                }
              >
                {relLevel < 3 ? 'AUMENTAR' : 'VER'} NÍVEL
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalEmail />
      <ModalTelefone />
    </>
  )
}

export default AlteracaoDadosCadastrais
