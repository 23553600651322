import { API_PATH } from '../../../commons/constants'
import fetchApi from '../../../commons/fetch-api'

export const getDevices = (setDevices, setOnlyAuthDevices) => async (
  dispatch,
  state
) => {
  const { id } = state.logged_user
  const pathRequest = API_PATH.conta.retrieveDevices.replace(':id', id)
  fetchApi(pathRequest, { method: 'GET' }).then(retornoObj => {
    setDevices(retornoObj.devices)
    setOnlyAuthDevices(retornoObj.authorizedDevicesOnly)
  })
}

export const removeDevice = (deviceId, setRemoved) => async (
  dispatch,
  state
) => {
  const { id } = state.logged_user
  const pathRequest = API_PATH.conta.removeDevice
    .replace(':id', id)
    .replace(':deviceId', deviceId)
  fetchApi(pathRequest, { method: 'DELETE' }).then(() => setRemoved(true))
}
