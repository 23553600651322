import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function FormField(props) {
  const {
    imgSrc,
    label,
    inputText = 'text',
    inputValue,
    disabled = true,
    onlySpan = false,
    span,
    faIcon,
    upsertFunction,
  } = props

  return (
    <>
      <div className="form-panel">
        <img className="form-panel-icon" src={imgSrc} alt="" />
        <div className="form-panel-fields">
          <label>{label}</label>
          {!onlySpan ? (
            <>
              <input type={inputText} value={inputValue} disabled={disabled} />
              {faIcon && faIcon !== null && (
                <FontAwesomeIcon icon={faIcon} onClick={upsertFunction} />
              )}
            </>
          ) : label === 'CPF' ? (
            <span>{window.cpfMask(inputValue)}</span>
          ) : (
            <span>{inputValue}</span>
          )}
        </div>
      </div>
    </>
  )
}
